import React from 'react';
import { Link } from 'gatsby';
import { Country } from './map-content';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface CountryWithImage extends Country {
    image: any;
}

interface DestinationListProps {
    destinations: CountryWithImage[];
}

const DestinationList: React.FC<DestinationListProps> = ({ destinations }) => {
    return (
        <div className="destination-list">
            {destinations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(country => (
                    <div key={country.code}>
                        <Link to={`/destinations/${country.code}`}>
                            <GatsbyImage className="cover" image={getImage(country.image)!} alt={country.name} />
                            <div className="text">{country.name}</div>
                        </Link>
                    </div>
                ))}
        </div>
    );
};

export default DestinationList;
